import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { CompatRouter, CompatRoute } from 'react-router-dom-v5-compat';
import {
  SplitContext,
  SplitFactoryProvider,
} from '@splitsoftware/splitio-react';

import { getSplitKey } from '@packages/core-split-feature-flag/src/getSplitKey/getSplitKey';
import { NotificationBar } from '@packages/deprecated-component-library/src/notificationBar/notificationBar';
import { GlobalErrorBoundary } from '@packages/core-components/src/components/error-boundary/global-error-boundary/globalErrorBoundary';
import { LoadingScreen } from '@packages/deprecated-component-library/src/components/loadingScreen';
import { AlleDownPage } from '@packages/deprecated-component-library/src/components/errorPages/alleDownPage';
import { ReactLazyPreload } from '@packages/deprecated-component-library/src/core/preload';

import { Header } from './layout/header/components/header';
import { Footer } from './layout/footer/components/footer';
import { InactivityTimerContainer } from './components/notifications/inactivityTimerContainer';
import { SecureRoute } from './auth/secureRoute';
import { routeConfig, RouteConfig } from './routeConfig';
import { Providers } from './providers';
import { App } from './app';
import {
  isAlleDown,
  SPLIT_DEBUGGER_ENABLED,
  splitProxy,
} from './application.config';

const ErrorPage404 = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'ErrorPage404' */ './views/errorPage404/errorPage404'
  ).then((mod) => ({
    default: mod.ErrorPage404,
  }))
);

const secureRouter = (route: RouteConfig, component: any) => {
  if (route.secure) {
    return (
      <SecureRoute
        key="no-key"
        title={route.title}
        path={route.path}
        exact={route.exact}
        component={component}
      />
    );
  } else if (route.ssr) {
    const Component = component as any;
    return (
      <CompatRoute
        key="no-key"
        path={route.path}
        exact={route.exact}
        component={() => (
          <>
            <Helmet title={route.title ? route.title : 'Allē'} />
            <Component />
          </>
        )}
      />
    );
  } else {
    return (
      <Route
        key="no-key"
        path={route.path}
        exact={route.exact}
        render={(props) => {
          const Component = component as any;
          return (
            <>
              <Helmet title={route.title ? route.title : 'Allē'} />
              <Component {...props} />
            </>
          );
        }}
      />
    );
  }
};
const SplitLoader = ({ children }: { children: any }) => {
  const { isReady, client } = React.useContext(SplitContext);
  if (isReady) {
    if (SPLIT_DEBUGGER_ENABLED) {
      console.log(
        `%cSplit ready, context splitKey: ${
          //@ts-ignore
          client?.key
        }`,
        'color:red;font-family:system-ui;font-size:1rem;-webkit-text-stroke: 1px black;font-weight:bold'
      );
    }

    return children;
  }
  return <LoadingScreen />;
};
const AppRouter = () => {
  if (isAlleDown) {
    return <AlleDownPage />;
  }

  return (
    <GlobalErrorBoundary>
      <SplitFactoryProvider
        config={{
          core: {
            authorizationKey: splitProxy.key,
            key: getSplitKey(SPLIT_DEBUGGER_ENABLED),
          },
          urls: { sdk: splitProxy.sdk, events: splitProxy.events },
        }}
        updateOnSdkUpdate={true}
        updateOnSdkTimedout={true}
      >
        <SplitLoader>
          <Router basename={process.env.PUBLIC_URL}>
            <CompatRouter>
              <React.Suspense fallback={<LoadingScreen />}>
                <Providers>
                  <App>
                    <div
                      css={`
                        position: relative;
                      `}
                    >
                      <Switch>
                        {routeConfig.map((route: RouteConfig) =>
                          route.header
                            ? secureRouter(route, route.header)
                            : null
                        )}
                        {/* Default to no header */}
                        <Route render={() => null} />
                      </Switch>
                      <NotificationBar responsive zIndex={1101} />
                      <Switch>
                        {routeConfig.map((route: RouteConfig) =>
                          secureRouter(route, route.component)
                        )}
                        {/* Default to 404 page */}
                        <Route
                          render={(props) => (
                            <>
                              <Header keepPathOnLogin {...props} />
                              <ErrorPage404 />
                              <Footer />
                            </>
                          )}
                        />
                      </Switch>
                      <Switch>
                        {routeConfig.map((route: RouteConfig) =>
                          route.hideFooter ? null : secureRouter(route, Footer)
                        )}
                        {/* Default to no footer */}
                        <Route component={() => null} />
                      </Switch>
                      <InactivityTimerContainer />
                    </div>
                  </App>
                </Providers>
              </React.Suspense>
            </CompatRouter>
          </Router>
        </SplitLoader>
      </SplitFactoryProvider>
    </GlobalErrorBoundary>
  );
};

export { AppRouter };
